import { TableModalTypes, TableState } from './tableState';

export enum PlayerActions {
  FOLD = 'FOLD',
  CHECK = 'CHECK',
  CALL = 'CALL',
  BET = 'BET',
  RAISE = 'RAISE',
  POST_BRING_IN = 'BRING IN',
  COMPLETE = 'COMPLETE',
  DISCARD = 'DISCARD',
  TIMEBANK = 'TIMEBANK',
  LEAVE_TABLE = 'LEAVE_TABLE',
  LEAVE_TABLE_ATTEMPT = 'LEAVE_TABLE_ATTEMPT',
  SET_WAIT_FOR_BIG_BLIND = 'SET_WAIT_FOR_BIG_BLIND',
  STAND_PAT = 'STAND PAT',
}

export enum TableRequests {
  TABLE_CONNECT = 'TABLE_CONNECT',
  RESERVE_SEAT = 'RESERVE_SEAT',
  RESERVE_SEAT_CANCEL = 'RESERVE_SEAT_CANCEL',
  TABLE_DISCONNECT = 'TABLE_DISCONNECT',
  LEAVE_TABLE = 'LEAVE_TABLE',
  INIT = 'INIT',
}

export enum TableIncomingEvents {
  TABLE_STATE = 'TABLE_STATE',
  TABLE_STATE_FOR_REPLAY = 'TABLE_STATE_FOR_REPLAY',
  TABLE_JOINED = 'TABLE_JOINED',
  TABLE_CONNECTED = 'TABLE_CONNECTED',
  TABLE_DISCONNECTED = 'TABLE_DISCONNECTED',
  TABLES_DISCONNECTED = 'TABLES_DISCONNECTED',
  SEAT_RESERVED = 'SEAT_RESERVED',
  RESERVE_SEAT_CANCELLED = 'RESERVE_SEAT_CANCELLED',
  TABLE_LEFT = 'TABLE_LEFT',
  HAND_FINISHED = 'HAND_FINISHED',
  PLAYER_TURN = 'PLAYER_TURN',
  ACTION_PERFORMED = 'ACTION_PERFORMED',
  SIT_OUT = 'SIT_OUT',
  SIT_IN = 'SIT_IN',
  ERROR = 'ERROR',
  DUPLICATE_TABLE_CONNECTION = 'DUPLICATE_TABLE_CONNECTION',
  AUTO_TOP_UP = 'AUTO_TOP_UP',
  SEAT_READY = 'SEAT_READY',
  REBUY_PROCESSED = 'REBUY_PROCESSED',
  AUTO_TOP_UP_BALANCE_UPDATED = 'AUTO_TOP_UP_BALANCE_UPDATED',
  TABLE_STATE_UPDATED = 'TABLE_STATE_UPDATED',
}

export enum PlayerIncomingEvents {
  CONFIRM = 'CONFIRM',
  MY_TURN = 'MY_TURN',
}

export enum TableInternalEvents {
  SET_TOGGLE_BB_DISPLAY = 'SET_TOGGLE_BB_DISPLAY',
  SHOW_TABLE_MODAL = 'SHOW_TABLE_MODAL',
  CLOSE_TABLE_MODAL = 'CLOSE_TABLE_MODAL',
  SOUND = 'SOUND',
  TABLE_JOINING = 'TABLE_JOINING',
  PLAY_SOUND = 'PLAY_SOUND',
  ADD_DISCARD_CARD = 'ADD_DISCARD_CARD',
  REMOVE_DISCARD_CARD = 'REMOVE_DISCARD_CARD',
  REFETCH_HISTORY = 'REFETCH_HISTORY',
  SET_PREACTION = 'SET_PREACTION',
  SET_BET_OR_RAISE_AMOUNT = 'SET_BET_OR_RAISE_AMOUNT',
  SET_PLAYER_SEAT = 'SET_PLAYER_SEAT',
}

// Action creators
export const autoTopUp = ({
  tableId,
  amount,
  txId,
  tableUrl,
}: {
  tableId: string;
  amount: number;
  txId?: string | undefined;
  tableUrl: string;
}) => ({
  type: TableIncomingEvents.AUTO_TOP_UP,
  data: {
    tableId,
    amount,
    txId,
    tableUrl,
  },
});

export const tableInit = (tableId: string) => ({
  type: TableRequests.INIT,
  tableId,
});
export const tableConnectAction = (tableId: string, tableUrl: string) => ({
  type: TableRequests.TABLE_CONNECT,
  tableId,
  tableUrl,
});

export const tableReserveAction = (tableId: string) => ({
  type: TableRequests.RESERVE_SEAT,
  tableId,
});

export const tableReserveCancelAction = (tableId: string) => ({
  type: TableRequests.RESERVE_SEAT_CANCEL,
  tableId,
});

export const tableStateUpdateForReplay = (tableId: string, tableState: TableState) => ({
  type: TableIncomingEvents.TABLE_STATE_FOR_REPLAY,
  tableId,
  data: tableState,
});

export const tableStateUpdate = (tableId: string, tableState: TableState | undefined, isSelfUpdate?: boolean) => ({
  type: TableIncomingEvents.TABLE_STATE,
  tableId,
  data: tableState,
  isSelfUpdate,
});

export const tablePlayerTurn = (
  tableId: string,
  data: {
    seat: number;
    timer: number;
    timeRemaining: number;
    usingTimebank: boolean;
  },
) => ({
  type: TableIncomingEvents.PLAYER_TURN,
  tableId,
  data,
});

export const tableSitOut = (tableId: string, userId: string) => ({
  type: TableIncomingEvents.SIT_OUT,
  tableId,
  data: {
    tableId,
    userId,
  },
});

export const tableSitIn = (tableId: string, userId: string) => ({
  type: TableIncomingEvents.SIT_IN,
  tableId,
  data: {
    tableId,
    userId,
  },
});

export const setToggleBBDisplay = (tableId: string, displayInBBEnabled: boolean) => ({
  type: TableInternalEvents.SET_TOGGLE_BB_DISPLAY,
  payload: { tableId, displayInBBEnabled },
});

export const showTableModal = (tableId: string | undefined, modalType: TableModalTypes) => ({
  type: TableInternalEvents.SHOW_TABLE_MODAL,
  data: {
    tableId,
    modalType,
  },
});

export const closeTableModal = (tableId: string | undefined) => ({
  type: TableInternalEvents.CLOSE_TABLE_MODAL,
  data: {
    tableId,
  },
});

export const tableSound = (
  tableId: string | undefined,
  lastOperation: {
    type: string;
    subtype: string;
    action: string;
  },
) => ({
  type: TableInternalEvents.SOUND,
  data: {
    tableId,
    lastOperation,
  },
});

export const tableJoining = (tableId: string, joining: boolean) => ({
  type: TableInternalEvents.TABLE_JOINING,
  data: {
    tableId,
    joining,
  },
});

export const timebankAction = (tableId: string) => ({
  type: PlayerActions.TIMEBANK,
  tableId,
});

export const foldAction = (tableId: string) => ({
  type: PlayerActions.FOLD,
  tableId,
  params: null,
});

export const checkAction = (tableId: string) => ({
  type: PlayerActions.CHECK,
  tableId,
  params: null,
});

export const completeAction = (tableId: string, amount?: number) => ({
  type: PlayerActions.COMPLETE,
  tableId,
  params: {
    amount,
  },
});

export const bringInAction = (tableId: string) => ({
  type: PlayerActions.POST_BRING_IN,
  tableId,
  params: null,
});

export const callAction = (tableId: string) => ({
  type: PlayerActions.CALL,
  tableId,
  params: null,
});

export const betAction = (tableId: string, amount?: number) => ({
  type: PlayerActions.BET,
  tableId,
  params: { amount },
});

export const raiseAction = (tableId: string, amount?: number) => ({
  type: PlayerActions.RAISE,
  tableId,
  params: { amount },
});

export const discardAction = (tableId: string, cards?: number[]) => {
  return {
    type: PlayerActions.DISCARD,
    tableId,
    params: { cards },
  };
};

export const leaveTableAction = ({ tableId, tableUrl }: { tableId: string; tableUrl: string }) => ({
  type: PlayerActions.LEAVE_TABLE,
  tableId,
  tableUrl,
});

export const leaveTableAttemptAction = (tableId: string) => ({
  type: PlayerActions.LEAVE_TABLE_ATTEMPT,
  tableId,
});

export const setWaitForBBAction = (tableId: string, waiting: boolean) => ({
  type: PlayerActions.SET_WAIT_FOR_BIG_BLIND,
  tableId,
  data: {
    waiting,
  },
});

export const addDiscardCardAction = (tableId: string, cardIndex: number) => {
  return {
    type: TableInternalEvents.ADD_DISCARD_CARD,
    data: { tableId, cardIndex },
  };
};

export const removeDiscardCardAction = (tableId: string, cardIndex: number) => {
  return {
    type: TableInternalEvents.REMOVE_DISCARD_CARD,
    data: { tableId, cardIndex },
  };
};

export const tableRefetchHistory = (tableId: string) => {
  return {
    type: TableInternalEvents.REFETCH_HISTORY,
    data: { tableId },
  };
};

export const setPreaction = (tableId: string, action: PlayerActions | '', amount?: number | undefined) => {
  return {
    type: TableInternalEvents.SET_PREACTION,
    tableId,
    data: { action, amount },
  };
};

export const setBetOrRaiseAmount = (tableId: string, amount?: number | undefined) => {
  return {
    type: TableInternalEvents.SET_BET_OR_RAISE_AMOUNT,
    tableId,
    data: { amount },
  };
};

export const setPlayerSeat = (tableId: string) => {
  return {
    type: TableInternalEvents.SET_PLAYER_SEAT,
    tableId,
  };
};
