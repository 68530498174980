import { BettingStructure } from '../common/types/bettingStructure';
import { PokerCategory } from '../common/types/pokerCategory';
import { GameParams, SeatDto } from './tableState';

export interface TableInfo {
  id: string;
  url: string;
  type: string;
  name: string;
  game: string;
  ante: number;
  smallBlind: number;
  bigBlind: number;
  minBuyin: number;
  maxBuyin: number;
  smallBet: number;
  bigBet: number;
  bringIn: number;
  bettingCap: number;
  players: number;
  maxPlayers: number;
  waitlist: [];
  avgPot: number;
  category: PokerCategory;
  bettingStructure: BettingStructure;
  variants: PokerVariant[];
  minStake: number;
  maxStake: number;
  gameParams: GameParams[];
  seats: SeatDto[];
  isHighStakes: boolean;
}

export interface PokerVariant {
  id: string;
  name: string;
  bettingStructure: BettingStructure;
  category: PokerCategory;
  maxPlayers: number;
  reshuffleRequired: boolean;
  capped: boolean;
}

export interface Card {
  rank: string;
  suit: string;
}

export enum EventSounds {
  FIRST_WARNING = 'FIRST_WARNING',
  SECOND_WARNING = 'SECOND_WARNING',
  DEAL = 'DEAL',
  FLOP = 'FLOP',
  BOARD_DEAL = 'BOARD_DEAL',
  CHIPS_PULLING = 'CHIPS_PULLING',
  CHIPS_PUSHING = 'CHIPS_PUSHING',
  RAISE = 'RAISE',
  BET = 'BET',
  FOLD = 'FOLD',
  CALL = 'CALL',
  CHECK = 'CHECK',
  WIN = 'WIN',
}

export type GetEventSound<T> = (tableMessage: T) => EventSounds | undefined;
