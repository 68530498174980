import { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import ROUTES from '@/routers/Routes';
import LogoLoadingSpinner from '@/components/LogoLoadingSpinner/LogoLoadingSpinner';

import SplashScreen from './SplashScreen/SplashScreen';
import { useMe } from '../hooks/useMe';
import { AuthEvents } from '@/services/auth/authActions';
import { useDispatch } from 'react-redux';
import { setPhnmPayoutPercentage } from '@/services/rake/rakeActions';

const AuthGuard = () => {
  const { ready, authenticated, user } = usePrivy();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referral') || searchParams.get('r');

  const {
    isLoading,
    data: userData,
    isSuccess,
    isFetched,
  } = useMe({
    enabled: ready && authenticated && !!user?.id,
    userId: user?.id,
  });

  // Handle user data updates
  useEffect(() => {
    if (userData?.data && isSuccess) {
      dispatch({
        type: AuthEvents.USER_DATA_LOADED,
        payload: userData.data,
      });
      if (userData.data.phnmPayoutPercentage !== undefined) {
        dispatch(setPhnmPayoutPercentage(userData.data.phnmPayoutPercentage));
      }
    }
  }, [userData?.data, isSuccess, dispatch]);

  // Handle loading state
  useEffect(() => {
    // other parts of the app use the redux auth loading state as the source of truth instead of the query loading state
    if (!isLoading && isFetched) {
      dispatch({
        type: AuthEvents.SET_LOADING,
        payload: false,
      });
    }
  }, [isLoading, isFetched, dispatch]);

  useEffect(() => {
    if (referralCode) {
      sessionStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  // Not ready or still loading user data
  if (!ready || (authenticated && isLoading)) {
    return (
      <SplashScreen logoHeader={false}>
        <LogoLoadingSpinner size="20" />
      </SplashScreen>
    );
  }

  if (!authenticated) {
    return <Navigate to={ROUTES.LOGIN} state={{ redirectTo: location }} />;
  }

  return <Outlet />;
};

export default AuthGuard;
