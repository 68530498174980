export interface Operation {
  action: string;
  subtype: string;
  player_index?: number;
}

export interface AntePosting extends Operation {
  player_index: number;
  amount: number;
}

export interface BetCollection extends Operation {
  bets: number[];
  totalBets: number;
}

export interface BlindOrStraddlePosting extends Operation {
  player_index: number;
  seat: number;
  amount: number;
}

export interface CardBurning extends Operation {
  card: string;
}

export interface HoleDealing extends Operation {
  player_index: number;
  seat: number;
  cards: string[];
  statuses: boolean[];
}

export interface BoardDealing extends Operation {
  cards: string[];
}

export interface StandingPatOrDiscarding extends Operation {
  player_index: number;
  seat: number;
  cards: string[];
}

export interface Folding extends Operation {
  player_index: number;
  seat: number;
}

export interface CheckingOrCalling extends Operation {
  player_index: number;
  seat: number;
  amount: number;
}

export interface BringInPosting extends Operation {
  player_index: number;
  seat: number;
  amount: number;
}

export interface CompletionBettingOrRaisingTo extends Operation {
  player_index: number;
  seat: number;
  amount: number;
}

export interface HoleCardsShowingOrMucking extends Operation {
  player_index: number;
  seat: number;
  holeCards: string[];
}

export interface HandKilling extends Operation {
  player_index: number;
  seat: number;
}

export interface ChipsPushing extends Operation {
  amounts: number[];
  raked_amount: number;
  info: PotInfo[];
}

export interface ChipsPulling extends Operation {
  player_index: number;
  seat: number;
  amount: number;
}

export enum Operations {
  AntePosting = 'AntePosting',
  BetCollection = 'BetCollection',
  BlindOrStraddlePosting = 'BlindOrStraddlePosting',
  CardBurning = 'CardBurning',

  HoleDealing = 'HoleDealing',
  BoardDealing = 'BoardDealing',
  StandingPatOrDiscarding = 'StandingPatOrDiscarding',
  Folding = 'Folding',
  CheckingOrCalling = 'CheckingOrCalling',
  BringInPosting = 'BringInPosting',
  CompletionBettingOrRaisingTo = 'CompletionBettingOrRaisingTo',
  HoleCardsShowingOrMucking = 'HoleCardsShowingOrMucking',
  HandKilling = 'HandKilling',
  ChipsPushing = 'ChipsPushing',
  ChipsPulling = 'ChipsPulling',
}

export interface AntePostingMsg {
  type: Operations.AntePosting;
  operation: AntePosting;
}

export interface BetCollectionMsg {
  type: Operations.BetCollection;
  operation: BetCollection;
}

export interface BlindOrStraddlePostingMsg {
  type: Operations.BlindOrStraddlePosting;
  operation: BlindOrStraddlePosting;
}

export interface CardBurningMsg {
  type: Operations.CardBurning;
  operation: CardBurning;
}

export interface HoleDealingMsg {
  type: Operations.HoleDealing;
  operation: HoleDealing;
}

export interface BoardDealingMsg {
  type: Operations.BoardDealing;
  operation: BoardDealing;
}

export interface StandingPatOrDiscardingMsg {
  type: Operations.StandingPatOrDiscarding;
  operation: StandingPatOrDiscarding;
}

export interface FoldingMsg {
  type: Operations.Folding;
  operation: Folding;
}

export interface CheckingOrCallingMsg {
  type: Operations.CheckingOrCalling;
  operation: CheckingOrCalling;
}

export interface BringInPostingMsg {
  type: Operations.BringInPosting;
  operation: BringInPosting;
}

export interface CompletionBettingOrRaisingToMsg {
  type: Operations.CompletionBettingOrRaisingTo;
  operation: CompletionBettingOrRaisingTo;
}

export interface HoleCardsShowingOrMucking extends Operation {
  player_index: number;
  holeCards: string[];
}

export interface HoleCardsShowingOrMuckingMsg {
  type: Operations.HoleCardsShowingOrMucking;
  operation: HoleCardsShowingOrMucking;
}

export interface HandKillingMsg {
  type: Operations.HandKilling;
  operation: HandKilling;
}

export interface ChipsPushingMsg {
  type: Operations.ChipsPushing;
  operation: ChipsPushing;
}

export interface ChipsPullingMsg {
  type: Operations.ChipsPulling;
  operation: ChipsPulling;
}

export type LastOperationMsg =
  | AntePostingMsg
  | BetCollectionMsg
  | BlindOrStraddlePostingMsg
  | CardBurningMsg
  | HoleDealingMsg
  | BoardDealingMsg
  | StandingPatOrDiscardingMsg
  | FoldingMsg
  | CheckingOrCallingMsg
  | BringInPostingMsg
  | CompletionBettingOrRaisingToMsg
  | HoleCardsShowingOrMuckingMsg
  | HandKillingMsg
  | ChipsPushingMsg
  | ChipsPullingMsg;

export interface LastOperationLabel {
  label?: string;
  playerIndex?: number;
  time?: number;
  seat: number;
}

export interface PotInfo {
  board_index: number;
  hand_type_index: number;
  players: PotPlayerInfo[];
}

export interface PotPlayerInfo {
  amount: number;
  player_index: number;
  winning_hand: string;
}
