import { AuthState } from './authState';
import { AuthEvents } from './authActions';

const initialState: AuthState = {
  authenticated: false,
  kyc: null,
  id: '',
  email: '',
  alias: '',
  firstName: '',
  lastName: '',
  loading: false,
  avaturnUser: undefined,
  smartWalletAddress: undefined,
  mainWalletAddress: undefined,
  settings: {
    feltColor: 'default',
    backgroundDesign: 'default',
    deckDesign: 'default',
    currentHandValueEnabled: false,
    autoTimeBankEnabled: true,
    runItTwiceEnabled: false,
    stackInBigBlindsEnabled: false,
    chipsInBigBlindsEnabled: false,
    animationsEnabled: true,
    autoMuckCardsEnabled: false,
    displayFoldedCardsEnabled: false,
    allInEquityValueEnabled: false,
    soundEnabled: true,
    modelsEnabled: true,
    stepInBigBlindsEnabled: true,
    tableTabsInDesktopEnabled: false,
    buttonConfigurations: {
      onBetButtons: [],
      onPreFlopButtons: [],
      postFlopButtons: [],
    },
    hotkeys: {
      betOrRaise: {
        ctrl: true,
        keys: ['ArrowUp'],
        meta: true,
      },
      bringIn: {
        ctrl: true,
        keys: ['ArrowRight'],
        meta: true,
      },
      call: {
        ctrl: true,
        keys: ['ArrowRight'],
        meta: true,
      },
      check: {
        ctrl: true,
        keys: ['ArrowRight'],
        meta: true,
      },
      complete: {
        ctrl: true,
        keys: ['ArrowUp'],
        meta: true,
      },
      confirm: {
        ctrl: false,
        keys: ['Enter'],
        meta: false,
      },
      fold: {
        ctrl: true,
        keys: ['ArrowLeft'],
        meta: true,
      },
    },
  },
};

function authReducer(
  state: AuthState = initialState,
  action: any, // Adjust the action type based on your project's type definitions
): AuthState {
  switch (action.type) {
    case AuthEvents.AUTHENTICATION_SUCCESS:
      // Assuming the payload contains a flag for KYC and the user's email
      return {
        ...state,
        authenticated: true,
      };

    case AuthEvents.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AuthEvents.USER_DATA_LOADED:
      // Assuming the payload contains additional user data you might want to store
      return {
        ...state,
        // Update the state with additional user data as needed
        loading: false,
        ...action.payload, // This is a simplistic approach; you might need to adjust based on actual payload structure
      };
    case AuthEvents.AUTHENTICATION_FAILURE:
      // Reset to initial state or adjust as needed for your app's logic
      return {
        ...initialState,
        // Optionally, store error information or handle it differently
      };
    case AuthEvents.LOGOUT:
      // Reset to initial state or adjust as needed for your app's logic
      return initialState;
    case AuthEvents.REGISTER_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case AuthEvents.UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case AuthEvents.UPDATE_LOBBY_FILTERS:
      return {
        ...state,
        lobbyFilters: { ...state?.lobbyFilters, ...action.payload },
      };
    default:
      return state;
  }
}

export default authReducer;
