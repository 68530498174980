import { useDispatch } from 'react-redux';
import { AxiosResponse, isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { me } from '@/services/auth/authService';
import { User } from '@/services/user/userTypes';
import { AuthEvents } from '@/services/auth/authActions';

export const useMe = (options?: { enabled?: boolean; userId?: string }) => {
  const dispatch = useDispatch();
  return useQuery<AxiosResponse<User>, Error>({
    queryKey: ['authenticated-user', options?.userId],
    queryFn: async () => {
      dispatch({
        type: AuthEvents.SET_LOADING,
        payload: true,
      });
      return me();
    },
    staleTime: Infinity,
    gcTime: 5 * 60 * 1000,
    enabled: (options?.enabled ?? true) && !!options?.userId,
    retry: (failureCount, error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};
