import { RakeState } from './rakeState';
import { RakeActions, RakeEvents } from './rakeActions';

const initialState: RakeState = {
  phnmPayoutPercentage: 0,
};

function rakeReducer(state: RakeState = initialState, action: RakeActions): RakeState {
  switch (action.type) {
    case RakeEvents.SET_PHNM_PAYOUT_PERCENTAGE:
      return {
        ...state,
        phnmPayoutPercentage: action.payload,
      };
    default:
      return state;
  }
}

export default rakeReducer;
