import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import useIsMobileView from '@/hooks/useIsMobileView';
import styles from './Modal.module.css';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
interface ModalProps {
  open: boolean;
  setClose?: () => void;
  children: React.ReactNode;
  blurBackground?: boolean;
  className?: string;
  bodyClassName?: string;
  contentClassName?: string;
  zIndex?: number;
  titleComponent?: React.ReactElement<ModalTitleProps> | undefined; // Modify this prop type
}
const Modal = ({
  open,
  setClose,
  children,
  blurBackground = true,
  className,
  bodyClassName,
  contentClassName,
  zIndex,
  titleComponent,
}: ModalProps) => {
  const isMobileView = useIsMobileView();

  const handleOnClose = useCallback(() => {
    if (setClose) {
      setClose();
    }
  }, [setClose]);

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <div className={twMerge('fixed inset-0 z-50 mx-auto', blurBackground && 'backdrop-blur-sm')} aria-hidden="true" />

      <div
        className="fixed inset-0 z-60 flex h-dvh w-screen items-center justify-center text-[16px]"
        style={{ zIndex: zIndex || 50 }}
      >
        <DialogPanel className={twMerge('box-content flex', className, isMobileView ? '' : styles.borderGlow)}>
          <div
            className={twMerge(
              !isMobileView && 'z-100 flex w-full flex-1 gap-0 rounded-[30px] bg-transparent text-white',
            )}
          >
            <div
              className={twMerge(
                'h-full w-full flex-1 rounded-[30px] bg-phenom-border-glow p-[1px]',
                isMobileView && 'h-dvh w-screen rounded-none p-0',
              )}
            >
              <div
                className={twMerge(
                  'relative flex h-full w-full flex-col rounded-[30px] bg-ph-background-dark p-8',
                  isMobileView && 'rounded-none !px-4',
                  contentClassName,
                )}
              >
                {titleComponent}
                <div className={twMerge('h-full', bodyClassName)}>{children}</div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export interface ModalTitleProps {
  onClose?: (() => void) | undefined;
  title?: string | undefined;
  subtitle?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const Title = ({ onClose, className, title, subtitle, children }: ModalTitleProps) => {
  return (
    <div className="mb-3 flex flex-col">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <div className={twMerge('flex items-center justify-between', className)}>
            {title && (
              <DialogTitle as="h2" className="text-2xl font-semibold leading-[32px] text-ph-gray-white">
                {title}
              </DialogTitle>
            )}
            {onClose && (
              <div className="ml-auto">
                <CloseModalButton onClose={onClose} />
              </div>
            )}
          </div>
          {subtitle && <Description className="text-sm text-ph-white-50">{subtitle}</Description>}
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.Title = Title;

export default Modal;
