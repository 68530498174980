// Direct effects on state
export enum RakeEvents {
  SET_PHNM_PAYOUT_PERCENTAGE = 'SET_PHNM_PAYOUT_PERCENTAGE',
}

export interface RakeActions {
  type: RakeEvents.SET_PHNM_PAYOUT_PERCENTAGE;
  payload: number;
}

// Typed action creators
export const setPhnmPayoutPercentage = (percentage: number) => ({
  type: RakeEvents.SET_PHNM_PAYOUT_PERCENTAGE,
  payload: percentage,
});
