import axiosBaseApi from '@/services/common/lib/axiosBaseApi';
import { useEffect } from 'react';

interface MemoryMetrics {
  deviceMemory: number | null;
  maxMemory: number;
  allocatedMemory: number;
  usedMemory: number;
}

interface PerformanceMetrics {
  pageLoad: number;
  domComplete: number;
  domInteractive: number;
  resourceCount: number;
  totalResourceTime: number;
  firstPaint: number | null;
  firstContentfulPaint: number | null;
}

interface CPUMetrics {
  hardwareConcurrency: number;
  frameRate: number | null;
  taskLatency: number;
}

interface DeviceInfo {
  // Browser Information
  browserName: string;
  browserVersion: string;
  engineName: string;

  // Device Information
  platform: string;
  deviceType: 'mobile' | 'tablet' | 'desktop' | 'unknown';

  screenWidth: number;
  screenHeight: number;
  screenPixelRatio: number;

  // Network Information
  connectionType: string | null;
  downlink: number | null;
  rtt: number | null;
  saveData: boolean | null;

  // System Capabilities
  onLine: boolean;
  webgl: boolean;
}

interface AllMetrics extends MemoryMetrics, PerformanceMetrics, CPUMetrics, DeviceInfo {
  url: string;
}

const postPerformanceMetrics = (metrics: AllMetrics) => {
  return axiosBaseApi.post<AllMetrics>(`/v1/performance/metrics`, metrics);
};

const usePerformanceMonitor = (interval = 60000) => {
  const getMemoryMetrics = async (): Promise<MemoryMetrics> => {
    let deviceMemory: number | null = null;
    let usage: Omit<MemoryMetrics, 'deviceMemory'> | null = {
      maxMemory: 0,
      allocatedMemory: 0,
      usedMemory: 0,
    };

    if ('deviceMemory' in navigator) {
      deviceMemory = (navigator as any).deviceMemory;
    }

    // Get Chrome memory usage
    if ('memory' in performance) {
      const memory = (performance as any).memory;
      usage = {
        maxMemory: Number((memory.jsHeapSizeLimit / 1048576).toFixed(2)),
        allocatedMemory: Number((memory.totalJSHeapSize / 1048576).toFixed(2)),
        usedMemory: Number((memory.usedJSHeapSize / 1048576).toFixed(2)),
      };
    }

    return {
      deviceMemory,
      ...usage,
    };
  };

  const getPerformanceMetrics = (): PerformanceMetrics => {
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    const resources = performance.getEntriesByType('resource') as PerformanceResourceTiming[];
    const paint = performance.getEntriesByType('paint') as PerformancePaintTiming[];

    const firstPaintEntry = paint.find((entry) => entry.name === 'first-paint');
    const firstContentfulPaintEntry = paint.find((entry) => entry.name === 'first-contentful-paint');

    return {
      pageLoad: navigation ? navigation.loadEventEnd - navigation.startTime : 0,
      domComplete: navigation ? navigation.domComplete : 0,
      domInteractive: navigation ? navigation.domInteractive : 0,
      resourceCount: resources.length,
      totalResourceTime: resources.reduce((total, resource) => total + (resource.responseEnd - resource.startTime), 0),
      firstPaint: firstPaintEntry ? firstPaintEntry.startTime : null,
      firstContentfulPaint: firstContentfulPaintEntry ? firstContentfulPaintEntry.startTime : null,
    };
  };

  const getCPUMetrics = async (): Promise<CPUMetrics> => {
    // Get number of logical processors
    const hardwareConcurrency = navigator.hardwareConcurrency || 1;

    // Measure frame rate
    let frameRate: number | null = null;
    if ('requestAnimationFrame' in window) {
      const frames = [];
      let lastTime = performance.now();

      for (let i = 0; i < 10; i++) {
        const time = await new Promise<number>((resolve) => {
          requestAnimationFrame((timestamp) => {
            resolve(timestamp);
          });
        });

        const delta = time - lastTime;
        if (delta > 0) {
          frames.push(1000 / delta); // Convert to fps
        }
        lastTime = time;
      }

      if (frames.length > 0) {
        frameRate = frames.reduce((a, b) => a + b) / frames.length;
      }
    }

    // Measure task latency
    const startTime = performance.now();
    let taskLatency = 0;

    await new Promise<void>((resolve) => {
      const worker = new Worker(
        URL.createObjectURL(new Blob([`postMessage('done');`], { type: 'application/javascript' })),
      );

      worker.onmessage = () => {
        taskLatency = performance.now() - startTime;
        worker.terminate();
        resolve();
      };
    });

    return {
      hardwareConcurrency,
      frameRate,
      taskLatency,
    };
  };

  const getDeviceInfo = (): DeviceInfo => {
    const ua = navigator.userAgent;

    const getBrowser = () => {
      const browsers = {
        chrome: /chrome|chromium|crios/i,
        firefox: /firefox|fxios/i,
        safari: /safari/i,
        edge: /edg/i,
        opera: /opera|opr/i,
        ie: /msie|trident/i,
      };

      for (const [name, regex] of Object.entries(browsers)) {
        if (regex.test(ua)) {
          const match = ua.match(
            /(?:chrome|chromium|crios|firefox|fxios|safari|edg|opera|opr|msie|trident)\/(\d+(\.\d+)?)/i,
          );
          return {
            name,
            version: match ? match[1] : 'unknown',
          };
        }
      }

      return { name: 'unknown', version: 'unknown' };
    };

    const getEngine = () => {
      const engines = {
        webkit: /webkit/i,
        gecko: /gecko/i,
        trident: /trident/i,
        presto: /presto/i,
        edgehtml: /edgehtml/i,
      };

      for (const [name, regex] of Object.entries(engines)) {
        if (regex.test(ua)) {
          return { name };
        }
      }

      return { name: 'unknown' };
    };

    const getDeviceType = () => {
      const tablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(ua);
      const mobile =
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);

      if (tablet) return 'tablet';
      if (mobile) return 'mobile';
      return 'desktop';
    };

    const connection =
      (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;

    const browser = getBrowser();
    const engine = getEngine();

    return {
      browserName: browser.name,
      browserVersion: browser.version,
      engineName: engine.name,
      platform: navigator.platform,
      deviceType: getDeviceType() as 'mobile' | 'tablet' | 'desktop' | 'unknown',
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      screenPixelRatio: window.devicePixelRatio,
      connectionType: connection?.effectiveType ?? null,
      downlink: connection?.downlink ?? null,
      rtt: connection?.rtt ?? null,
      saveData: connection?.saveData ?? null,
      onLine: navigator.onLine,
      webgl: (() => {
        try {
          const canvas = document.createElement('canvas');
          return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
          );
        } catch (e) {
          console.error('Error checking WebGL support:', e);
          return false;
        }
      })(),
    };
  };

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const collectMetrics = async () => {
      // Only run if the document is visible
      if (document.visibilityState !== 'visible') return;

      try {
        const [memoryData, cpuData] = await Promise.all([getMemoryMetrics(), getCPUMetrics()]);
        const performanceData = getPerformanceMetrics();
        const deviceData = getDeviceInfo();

        const allMetrics: AllMetrics = {
          url: window.location.href,
          ...performanceData,
          ...memoryData,
          ...cpuData,
          ...deviceData,
        };

        try {
          await postPerformanceMetrics(allMetrics);
        } catch (error) {
          console.error('Error posting metrics:', error);
        }
      } catch (error) {
        console.error('Error collecting metrics:', error);
      }
    };

    const startInterval = () => {
      if (!intervalId) {
        intervalId = setInterval(collectMetrics, interval);
      }
    };

    const stopInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
        // Collect metrics immediately upon refocus
        collectMetrics();
        // Restart interval
        startInterval();
      } else {
        // Stop interval while hidden
        stopInterval();
      }
    };

    // Add event listener to watch visibility changes
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    // If initially visible, start immediately
    if (document.visibilityState === 'visible') {
      collectMetrics();
      startInterval();
    }

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      if (intervalId) clearInterval(intervalId);
    };
  }, [interval]);

  return null;
};

export default usePerformanceMonitor;
