import ReactDOM from 'react-dom/client';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { hardhat, polygon, mainnet } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PrivyProvider } from '@privy-io/react-auth';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import * as Sentry from '@sentry/react';
import UnregisterServiceWorker from '@/components/UnregisterServiceWorker';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import logo from '@/assets/phenom-logo.svg';
import config from '@/common/config';
import { Toaster } from '@/components/toast/toaster';
import { Profiler } from 'react';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

if (process.env.REACT_APP_NODE_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: process.env.REACT_APP_NODE_ENV === 'production' ? 0.1 : 1.0,
    tracePropagationTargets: [`*.api.phenompoker.com`],
    replaysSessionSampleRate: process.env.REACT_APP_NODE_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: process.env.REACT_APP_NODE_ENV === 'production' ? 0.1 : 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

export const wagmiConfig = createConfig({
  chains: [polygon],
  transports: {
    [polygon.id]: http(process.env.REACT_APP_RPC_URL),
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <Profiler id="app" onRender={() => null}>
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.REACT_APP_FINGERPRINT_KEY || '123456',
      }}
    >
      <PrivyProvider
        appId={process.env.REACT_APP_PRIVY_APP_ID || ''}
        config={{
          // Display email and wallet as login methods
          loginMethods: ['email', 'google', 'wallet'],
          // Customize Privy's appearance in your app
          appearance: {
            theme: 'dark',
            accentColor: '#A958D7',
            logo: logo,
          },
          // Create embedded wallets for users who don't have a wallet
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
          supportedChains: [polygon, mainnet, hardhat],
          defaultChain: config.chain,
        }}
      >
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
            <UnregisterServiceWorker />
            <Toaster />
          </QueryClientProvider>
        </WagmiProvider>
      </PrivyProvider>
    </FpjsProvider>
  </Profiler>,
);

reportWebVitals();
