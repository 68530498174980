import { AuthResponse } from '@supabase/supabase-js';

import { UserCreatePrivy } from '@/features/auth/interfaces/privy.interface';

import axiosBaseApi from '../common/lib/axiosBaseApi';
import {
  UpdateUser,
  User,
  UserProfile,
  UserTierProgression,
  UserWallet,
  UserWalletChallenge,
  UserWalletChallengeResponse,
  UserTierLevel,
} from './userTypes';

export const getWalletChallange = async (userId: string) =>
  await axiosBaseApi.get<UserWalletChallengeResponse>(`/v1/user/${userId}/wallet-challenge`);

export const sendWalletChallenge = async ({ userId, address, signature }: UserWalletChallenge) =>
  await axiosBaseApi.post(`/v1/user/${userId}/wallet-challenge`, {
    address,
    signature,
  });

export const getUserWallet = async (address: string) =>
  await axiosBaseApi.get<UserWallet>(`/v1/user/${address}/user-wallet`);

export const getUserTierProgression = async (userId: string | undefined | null) => {
  if (!userId) {
    throw new Error('User ID is required');
  }
  return await axiosBaseApi.get<UserTierProgression>(`/v1/player-tier/${userId}`);
};

export const getUser = async (userId: string | undefined | null) => {
  if (!userId) {
    throw new Error('User ID is required');
  }
  return await axiosBaseApi.get<User>(`/v1/user/${userId}`);
};

export const getPlayerTier = async (userId: string) =>
  await axiosBaseApi.get<UserTierLevel>(`/v1/player-tier/${userId}/tier`);

export const registerUser = async (newUser: UserCreatePrivy) =>
  await axiosBaseApi.post<User>(`/v1/user/register`, newUser);

export const updateRegisteredUser = async (user: Omit<UpdateUser, 'id'>) =>
  await axiosBaseApi.put<User>(`/v1/user`, user);

export const logoutOtherUserDevices = async (user: Pick<UpdateUser, 'visitorId'>) =>
  await axiosBaseApi.patch('/v1/user/logout-duplicate-device', user);

export const acceptGreeting = async (userId: string) =>
  await axiosBaseApi.patch<User>(`/v1/user/${userId}/accept-greeting`);

export const getUserProfile = async (userId: string) =>
  await axiosBaseApi.get<UserProfile>(`/v1/user/${userId}/profile`);

export const getProductBoardToken = async () => await axiosBaseApi.get<string>(`/v1/user/product-board-token`);
